import Cookies from 'js-cookie'
import { processData, remove } from "../utils/cookieUtils";

const TokenKey = 'UchungToken'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    Cookies.set(TokenKey, token)
    return processData({TokenKey, token});
}

export function removeToken() {
    // 清除所有cookie
    remove()
    return Cookies.remove(TokenKey)
}

export function getTokenByKey(TokenKey) {
    return Cookies.get(TokenKey)
}

export function setTokenByKey(TokenKey,token) {
    return Cookies.set(token)
}

export function removeTokenByKey(TokenKey) {
    return Cookies.remove(TokenKey)
}