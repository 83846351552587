import dayjs from 'dayjs'

export function dateFormat(val){
    if (val!=null){
       let  date =dayjs(val).format('YYYY-MM-DD HH:mm:ss')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function clickInDateFormat(val){
    if (val!=null){
        let  date =dayjs(val).format('YYYY-MM-DD HH:mm')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function birthDateFormat(val){
    if (val!=null){
        return dayjs(val).format('YYYY-MM-DD')
    }else {
        return "未填写"
    }
}

export function sexType(value) {
    let sex = "";
    switch (value){
        case 0:
            sex = '男';
            break;
        case 1:
            sex = '女';
            break;
        default:
            sex = '未知';
            break;
    }
    return sex;
}

export function couponType(value) {
    let coupon = "";
    switch (value){
        case 0:
            coupon = '满减';
            break;
        case 1:
            coupon = '立减';
            break;
        default:
            coupon = '折扣';
            break;
    }
    return coupon;
}

export function activityStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '启用';
            break;
        case 1:
            state = '未启用';
            break;
        default:
            state = '已过期';
            break;
    }
    return state;
}

export function sjType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '时';
            break;
        case 1:
            state = '分';
            break;
        case 2:
            state = '天';
            break;
        case 3:
            state = '月';
            break;
        default:
            state = '年';
            break;
    }
    return state;
}

export function orderStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '未支付';
            break;
        case 1:
            state = '已支付';
            break;
        case 2:
            state = '已退款';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function channel(value) {
    let state = "";
    switch (value){
        case 1:
            state = '在线咨询';
            break;
        case 2:
            state = '线下咨询';
            break;
        case 3:
            state = '客户自填';
            break;
        default:
            state = '其它';
            break;
    }
    return state;
}

export function consultChannel(value) {
    let state = "";
    switch (value){
        case 1:
            state = '微信';
            break;
        case 2:
            state = '电话';
            break;
        case 3:
            state = '来访登记';
            break;
        default:
            state = '其它';
            break;
    }
    return state;
}

export function followupWay(value) {
    let state = "";
    switch (value){
        case 1:
            state = '微信';
            break;
        case 2:
            state = '电话';
            break;
        case 3:
            state = '邮件';
            break;
        case 4:
            state = '线下';
            break;
        default:
            state = '其它';
            break;
    }
    return state;
}

export function intendedCustomerStatus(value) {
    let state = "";
    switch (value){
        case 1:
            state = '未签约';
            break;
        case 2:
            state = '约定中';
            break;
        default:
            state = '已签约';
            break;
    }
    return state;
}

export function intendedCustomerStatus2(value) {
    let state = "";
    switch (value){
        case 1:
            state = '意向客户';
            break;
        case 2:
            state = '正式客户';
            break;
        default:
            state = '正式客户';
            break;
    }
    return state;
}

export function consultStatus(value) {
    let state = "";
    switch (value){
        case 1:
            state = '待回访';
            break;
        default:
            state = '已回访';
            break;
    }
    return state;
}

export function spaceGoodsType(value) {
    let state = "";
    switch (value){
        case 1:
            state = '房间';
            break;
        case 2:
            state = '工位';
            break;
        case 3:
            state = '仓库';
            break;
        default:
            state = '商铺';
            break;
    }
    return state;
}


export function servicesType(value) {
    let state = "";
    switch (value){
        case 1:
            state = '办公家具';
            break;
        case 2:
            state = '物业费';
            break;
        default:
            state = '保洁服务';
            break;
    }
    return state;
}

export function spaceGoodsDecoration(value) {
    let state = "";
    switch (value){
        case 1:
            state = '毛胚';
            break;
        case 2:
            state = '简装';
            break;
        case 3:
            state = '精装';
            break;
        default:
            state = '清水装修';
            break;
    }
    return state;
}

export function timeUnit(value) {
    let state = "";
    switch (value){
        case 1:
            state = '分钟';
            break;
        case 2:
            state = '小时';
            break;
        case 3:
            state = '天';
            break;
        default:
            state = '月';
            break;
    }
    return state;
}

export function shareSpaceType(value) {
    let state = "";
    switch (value){
        case 1:
            state = '会议室';
            break;
        case 2:
            state = '路演厅';
            break;
        default:
            state = '共享工位';
            break;
    }
    return state;
}

// export function payStatus(value) {
//     let state = "";
//     switch (value){
//         case 'SUCCESS':
//             state = '支付成功';
//             break;
//         case 'REFUND':
//             state = '转入退款';
//             break;
//         case 'NOTPAY':
//             state = '未支付';
//             break;
//         case 'CLOSED':
//             state = '已关闭';
//             break;
//         case 'REVOKED':
//             state = '已撤销';
//             break;
//         case 'USERPAYING':
//             state = '用户支付中';
//             break;
//         case 'PAYERROR':
//             state = '支付失败';
//             break;
//         default:
//             state = '共享工位';
//             break;
//     }
//     return state;
// }

export function payStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '未支付';
            break;
        case 1:
            state = '已支付';
            break;
        case 2:
            state = '预约中';
            break;
        case 3:
            state = '已结束';
            break;
        case 4:
            state = '已退费';
            break;
        default:
            state = '未知状态';
            break;
    }
    return state;
}

export function spaceOrderPayStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '未支付';
            break;
        case 1:
            state = '已支付';
            break;
        case 2:
            state = '待生成合同';
            break;
        case 3:
            state = '已生成合同';
            break;
        case 4:
            state = '已退费';
            break;
        default:
            state = '未知状态';
            break;
    }
    return state;
}
export function totalOrderStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '进行中';
            break;
        case 1:
            state = '已完成';
            break;
        default:
            state = '未知状态';
            break;
    }
    return state;
}

export function intention(value) {
    let state = "";
    switch (value){
        case 0:
            state = '低';
            break;
        case 1:
            state = '中';
            break;
        case 2:
            state = '高';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function zsTypeFilter(value) {
    let state = "";
    switch (value){
        case 0:
            state = '租';
            break;
        case 1:
            state = '售';
            break;
        case 2:
            state = '其他';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}
export function viewingsStatusFilter(value) {
    let state = "";
    switch (value){
        case 0:
            state = '已提交';
            break;
        case 1:
            state = '已通过';
            break;
        case 2:
            state = '已拒绝';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function clueStatus(value) {
    let state = "";
    switch (value){
        case 1:
            state = '转化中';
            break;
        case 2:
            state = '转化成功';
            break;
        case 3:
            state = '放弃线索';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function customStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '待跟进';
            break;
        case 1:
            state = '跟进中';
            break;
        case 2:
            state = '成交';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function customSource(value) {
    let state = "";
    switch (value){
        case 0:
            state = '意向客户转化';
            break;
        case 1:
            state = '后台录入';
            break;
        case 2:
            state = '线索转化';
            break;
        default:
            state = '小程序录入';
            break;
    }
    return state;
}

export function followUpStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '上门拜访';
            break;
        case 1:
            state = '电话';
            break;
        case 2:
            state = '微信';
            break;
        case 3:
            state = '带看';
            break;
        case 4:
            state = '成交';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function brokerType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '招商员工';
            break;
        case 1:
            state = '招商经理';
            break;
        case 2:
            state = '招商主管';
            break;
        case 3:
            state = '财务员工';
            break;
        case 4:
            state = '宣发审核';
            break;
        case 5:
            state = '资产员工';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function zsType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '不租/售';
            break;
        case 1:
            state = '可租';
            break;
        case 2:
            state = '可售';
            break;
        case 3:
            state = '可租/售';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function zsTypes(value) {
    let state = "";
    switch (value){
        case 0:
            state = '租';
            break;
        case 1:
            state = '售';
            break;
        case 2:
            state = '其它';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function spaceStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '待租/待售';
            break;
        case 1:
            state = '已租';
            break;
        case 2:
            state = '已售';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function viewStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '已提交';
            break;
        case 1:
            state = '已通过';
            break;
        case 2:
            state = '已拒绝';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function ordersStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '信息填报';
            break;
        case 1:
            state = '已生成合同待审批';
            break;
        case 2:
            state = '履约中';
            break;
        case 3:
            state = '订单结束';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export default {
    install(Vue) {
        Vue.filter('dateFormat', dateFormat)
        Vue.filter('birthDateFormat', birthDateFormat)
        Vue.filter('clickInDateFormat', clickInDateFormat)
        Vue.filter('sexType', sexType)
        Vue.filter('couponType', couponType)
        Vue.filter('activityStatus', activityStatus)
        Vue.filter('sjType', sjType)
        Vue.filter('orderStatus', orderStatus)
        Vue.filter('channel', channel)
        Vue.filter('intendedCustomerStatus', intendedCustomerStatus)
        Vue.filter('consultChannel', consultChannel)
        Vue.filter('consultStatus', consultStatus)
        Vue.filter('followupWay', followupWay)
        Vue.filter('spaceGoodsType', spaceGoodsType)
        Vue.filter('spaceGoodsDecoration', spaceGoodsDecoration)
        Vue.filter('servicesType', servicesType)
        Vue.filter('timeUnit', timeUnit)
        Vue.filter('shareSpaceType', shareSpaceType)
        Vue.filter('payStatus', payStatus)
        Vue.filter('intention', intention)
        Vue.filter('clueStatus', clueStatus)
        Vue.filter('customStatus', customStatus)
        Vue.filter('spaceOrderPayStatus', spaceOrderPayStatus)
        Vue.filter('customSource', customSource)
        Vue.filter('followUpStatus', followUpStatus)
        Vue.filter('brokerType', brokerType)
        Vue.filter('zsType', zsType)
        Vue.filter('zsTypes', zsTypes)
        Vue.filter('viewStatus', viewStatus)
        Vue.filter('ordersStatus', ordersStatus)
        Vue.filter('spaceStatus', spaceStatus)
    }
}
