import request from "@/utils/request";
function getUrl(url) {
    return "/user-auth/" + url;
}

export function getUserInfo() {
    return request({
        url: getUrl("auth/getUserInfo"),
        method: 'get'
    })
}
export function login(data) {
    return request({
        url: getUrl("auth/login"),
        method: 'post',
        data
    })
}
export function getMenuTree(data) {
    return request({
        url: getUrl("menu/getMenuTree"),
        method: 'post',
        data
    })
}