import request from "@/utils/request";

function getUrl(url) {
    return "/business/" + url;
}

export default {
    listByOrder(data){
        return request({
            url: getUrl("orderGoods/listByOrder/"+data),
            method: "get"
        })
    },
    pageByOrder(data){
        return request({
            url: getUrl("orderGoods/pageByOrder"),
            method: "post",
            data
        })
    },
}
