import Vue from "vue";
import VueRouter from "vue-router";
import ecareLay from "@/ecareLay/index.vue";

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;

// push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export const commonRouters = [
    {
        path: "/login",
        hidden: true,
        meta: {title: "登录", icon: "el-icon-ed-shouye", affix: true},
        component: () => import(/* webpackChunkName: "login" */ "../pages/login/index.vue"),
    },
    {
        path: "/zscockpit",
        hidden: true,
        meta: {title: "高铁开发集团招商数据驾驶舱", icon: "el-icon-ed-shouye", affix: true},
        component: () => import(/* webpackChunkName: "login" */ "../pages/zscockpit/index.vue"),
    },
    {
        path: "/common/space",
        hidden: true,
        meta: {title: "账单详情", icon: "el-icon-ed-shouye", affix: true},
        component: () => import(/* webpackChunkName: "login" */ "../pages/common/spaceOrder/info.vue"),
    },
    {
        path: '/chart',
        hidden: true,
        component: () => import('../pages/common/chart/index'),
    },
    {
        path: '/spaceGoodsOrder/info',
        component: () => import('@/pages/trade/spaceGoodsOrder/info.vue'),
        hidden: true,
    },
    {
        path: '/trade/spaceGoodsOrderOpen/info',
        component: () => import('@/pages/trade/spaceGoodsOrderNew/info.vue'),
        hidden: true,
    },
    {
        path: '/shareSpaceOrder/info',
        component: () => import('@/pages/trade/shareSpaceOrder/info.vue'),
        hidden: true,
    },
    {
        path: '/spaceGoodsOrderNew/orderItems',
        component: () => import('@/pages/trade/spaceGoodsOrderNew/orderItems.vue'),
        hidden: true,
    },
    {
        path: '/spaceOrder/list',
        component: () => import('@/pages/trade/spaceGoodsOrderNew/list.vue'),
        hidden: true,
    },
    {
        path: '/shareOrder/list',
        component: () => import('@/pages/trade/shareSpaceOrder/list.vue'),
        hidden: true,
    },
    {
        path: "/",
        component: ecareLay,
        redirect: "/index",
        children: [
            {
                path: "/index",
                meta: {title: "首页", activeMenu: '/index', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/home/index.vue"),
            }
        ],
    },
    {
        path: "/401",
        hidden: true,
        meta: {title: "401", icon: "el-icon-ed-shouye", affix: true},
        component: () => import(/* webpackChunkName: "login" */ "../pages/common/401/index.vue"),
    },
    {
        path:'/asset',
        component:ecareLay,
        redirect: '/asset/list',
        meta: { title: '资产管理', icon: 'el-icon-uc-shujuguanli', affix: false },
        children: [
            {
                path: 'list',
                component: () => import('@/pages/asset/asset/list.vue'),
                name: 'asset',
                hidden: true,
                meta: { title: '资产管理', activeMenu:'/asset/list', affix: false}
            },
            {
                path: 'info',
                component: () => import('@/pages/asset/asset/info.vue'),
                name: 'asset',
                hidden: true,
                meta: { title: '资产详情', activeMenu:'/asset/list', affix: false}
            },
            {
                path: 'material',
                component: () => import('@/pages/asset/material/list.vue'),
                name: 'material',
                hidden: true,
                meta: { title: '宣传品物料管理', activeMenu:'/asset/material', affix: false}
            },
            {
                path: 'material/info',
                component: () => import('@/pages/asset/material/info.vue'),
                name: 'material/info',
                hidden: true,
                meta: { title: '宣传品物料详情', activeMenu:'/asset/material', affix: false}
            },
            {
                path: 'contract',
                component: () => import('@/pages/asset/contract/list.vue'),
                name: 'contract',
                hidden: true,
                meta: { title: '资产合同管理', activeMenu:'/asset/contract', affix: false}
            },
            {
                path: 'building',
                component: () => import('@/pages/asset/building/list.vue'),
                name: 'building',
                hidden: true,
                meta: { title: '楼宇数据管理', activeMenu:'/asset/building', affix: false}
            },
            {
                path: 'building/floor',
                component: () => import('@/pages/asset/building/floor/list.vue'),
                name: 'building/floor',
                hidden: true,
                meta: { title: '楼宇楼层管理', activeMenu:'/asset/building', affix: false}
            },
            {
                path: 'unit',
                component: () => import('@/pages/asset/building/unit/list.vue'),
                name: 'unit',
                hidden: true,
                meta: { title: '楼层单元管理', activeMenu:'/asset/unit', affix: false}
            },
        ]
    },{
        path:'/goods',
        component:ecareLay,
        redirect: '/goods/list',
        meta: { title: '商品管理', icon: 'el-icon-uc-shujuguanli', affix: false },
        children: [
            {
                path: 'list',
                component: () => import('@/pages/goods/spaceGoods/list.vue'),
                name: 'spaceGoods',
                hidden: true,
                meta: { title: '空间商品管理', activeMenu:'/goods/list', affix: false}
            },
            {
                path: 'spaceGoods/eq',
                component: () => import('@/pages/goods/spaceGoods/eq/list.vue'),
                name: 'spaceGoods/eq',
                hidden: true,
                meta: { title: '空间商品硬件管理', activeMenu:'/goods/list', affix: false, parent:"/goods/list"}
            },
            {
                path: 'spaceGoods/info',
                component: () => import('@/pages/goods/spaceGoods/info/list.vue'),
                name: 'spaceGoods/info',
                hidden: true,
                meta: { title: '空间商品详情', activeMenu:'/goods/list', affix: false, parent:"/goods/list"}
            },
            {
                path: 'salesChart',
                component: () => import('@/pages/goods/salesChart/index.vue'),
                name: 'salesChart',
                hidden: true,
                meta: { title: '控销图管理', activeMenu:'/goods/salesChart', affix: false}
            },
            {
                path: 'salesChart1',
                component: () => import('@/pages/goods/salesChart/index1.vue'),
                name: 'salesChart1',
                hidden: true,
                meta: { title: '控销图管理', activeMenu:'/goods/salesChart', affix: false}
            },
            {
                path: 'services',
                component: () => import('@/pages/goods/services/list.vue'),
                name: 'services',
                hidden: true,
                meta: { title: '增值服务管理', activeMenu:'/goods/services', affix: false}
            },
            {
                path: 'shareSpace',
                component: () => import('@/pages/goods/shareSpace/list.vue'),
                name: 'shareSpace',
                hidden: true,
                meta: { title: '共享空间管理', activeMenu: '/goods/shareSpace', affix: false}
            },
            {
                path: 'shareSpace/eq',
                component: () => import('@/pages/goods/shareSpace/eq/list.vue'),
                name: 'shareSpace/eq',
                hidden: true,
                meta: { title: '共享空间硬件管理', activeMenu: '/goods/shareSpace', affix: false, parent:"/goods/shareSpace"}
            },
            {
                path: 'shareSpace/info',
                component: () => import('@/pages/goods/shareSpace/info/info.vue'),
                name: 'shareSpaceInfo',
                hidden: true,
                meta: { title: '共享空间详情', activeMenu: '/goods/shareSpace', affix: false, parent:"/goods/shareSpace"}
            },
        ]
    },{
        path:'/trade',
        component:ecareLay,
        redirect: '/trade/spaceGoodsOrderNew',
        meta: { title: '交易管理', icon: 'el-icon-uc-shujuguanli', affix: false },
        children: [
            {
                path: 'spaceGoodsOrder',
                component: () => import('@/pages/trade/spaceGoodsOrder/list.vue'),
                name: 'spaceGoodsOrder',
                hidden: true,
                meta: { title: '空间订单管理', activeMenu: '/trade/spaceGoodsOrder', affix: false}
            },
            {
                path: 'spaceGoodsOrder/info',
                component: () => import('@/pages/trade/spaceGoodsOrder/info.vue'),
                name: 'spaceGoodsOrder/info',
                hidden: true,
                meta: { title: '空间订单详情', activeMenu: '/trade/spaceGoodsOrder', affix: false}
            },
            //NEW START
            {
                path: 'spaceGoodsOrderNew/info',
                component: () => import('@/pages/trade/spaceGoodsOrderNew/info.vue'),
                name: 'spaceGoodsOrderNew/info',
                hidden: true,
                meta: { title: '空间订单详情', activeMenu: '/trade/spaceGoodsOrderNew', affix: false}
            },
            {
                path: 'spaceGoodsOrderNew',
                component: () => import('@/pages/trade/spaceGoodsOrderNew/list.vue'),
                name: 'spaceGoodsOrderNew',
                hidden: true,
                meta: { title: '空间订单管理', activeMenu: '/trade/spaceGoodsOrderNew', affix: false}
            },
            {
                path: 'spaceGoodsOrderNew/orderItems',
                component: () => import('@/pages/trade/spaceGoodsOrderNew/orderItems.vue'),
                name: 'spaceGoodsOrderNew/orderItems',
                hidden: true,
                meta: { title: '空间订单付款项详情', activeMenu: '/trade/spaceGoodsOrderNew', affix: false}
            },
            //NEW END
            {
                path: 'shareSpaceOrder',
                component: () => import('@/pages/trade/shareSpaceOrder/list.vue'),
                name: 'shareSpaceOrder',
                hidden: true,
                meta: { title: '共享空间订单管理', activeMenu: '/trade/shareSpaceOrder', affix: false}
            },

            {
                path: 'shareSpaceOrder/info',
                component: () => import('@/pages/trade/shareSpaceOrder/info.vue'),
                name: 'shareSpaceOrder/info',
                hidden: true,
                meta: { title: '共享空间订单详情', activeMenu: '/trade/shareSpaceOrder', affix: false}
            },
            {
                path: 'signing',
                component: () => import('@/pages/trade/signing/list.vue'),
                name: 'signing',
                hidden: true,
                meta: { title: '签约归档', activeMenu: '/trade/signing', affix: false}
            },
            {
                path: 'template',
                component: () => import('@/pages/trade/template/list.vue'),
                name: 'template',
                hidden: true,
                meta: { title: '签约模板', activeMenu: '/trade/template', affix: false}
            },
            {
                path: 'taxRate',
                component: () => import('@/pages/trade/taxRate/list.vue'),
                name: 'taxRate',
                hidden: true,
                meta: { title: '交易配置', activeMenu: '/trade/taxRate', affix: false}
            },
            {
                path: 'orderType',
                component: () => import('@/pages/trade/orderType/list.vue'),
                name: 'orderType',
                hidden: true,
                meta: { title: '付款项类型管理', activeMenu: '/trade/orderType', affix: false}
            },
            {
                path: 'covenantTemplate',
                component: () => import('@/pages/trade/covenantTemplate/list.vue'),
                name: 'covenantTemplate',
                hidden: true,
                meta: { title: '合同模板管理', activeMenu: '/trade/covenantTemplate', affix: false}
            },
        ]
    },{
        path:'/investment',
        component:ecareLay,
        // redirect: '/investment/channel',
        // meta: { title: '招商管理', icon: 'el-icon-uc-shujuguanli', affix: false },
        children: [
            {
                path: 'channel',
                component: () => import('@/pages/investment/channel/list.vue'),
                name: 'channel',
                hidden: true,
                meta: { title: '渠道管理', activeMenu: '/investment/channel', affix: false}
            },
            {
                path: 'dept',
                component: () => import('@/pages/investment/dept/index.vue'),
                name: 'dept',
                hidden: true,
                meta: { title: '部门管理', activeMenu: '/investment/dept', affix: false}
            },
            {
                path: 'broker',
                component: () => import('@/pages/investment/broker/list.vue'),
                name: 'broker',
                hidden: true,
                meta: { title: '员工管理', activeMenu: '/investment/broker', affix: false}
            },
            {
                path: 'broker/info',
                component: () => import('@/pages/investment/broker/info.vue'),
                name: 'broker/info',
                hidden: true,
                meta: { title: '员工详情', activeMenu: '/investment/broker', affix: false}
            },
            {
                path: 'clue',
                component: () => import('@/pages/investment/clue/list.vue'),
                name: 'clue',
                hidden: true,
                meta: { title: '意向客户', activeMenu: '/investment/clue', affix: false}
            },
            {
                path: 'consult',
                component: () => import('@/pages/investment/clue/consult/list.vue'),
                name: 'consult',
                hidden: true,
                meta: { title: '咨询登记', activeMenu: '/investment/clue', affix: false}
            },
            {
                path: 'followup',
                component: () => import('@/pages/investment/clue/followup/list.vue'),
                name: 'followup',
                hidden: true,
                meta: { title: '回访记录',  activeMenu: '/investment/clue', affix: false}
            },
            {
                path: 'custom',
                component: () => import('@/pages/investment/custom/list.vue'),
                name: 'custom',
                hidden: true,
                meta: { title: '客户管理', activeMenu: '/investment/custom', affix: false}
            },
            {
                path: 'custom/info',
                component: () => import('@/pages/investment/custom/info.vue'),
                name: 'custom/info',
                hidden: true,
                meta: { title: '客户详情', activeMenu: '/investment/custom', affix: false, parent:"/investment/custom"}
            },
            {
                path: 'custom/followUp',
                component: () => import('@/pages/investment/custom/followUp/list.vue'),
                name: 'custom/followUp',
                hidden: true,
                meta: { title: '跟进管理', activeMenu: '/investment/custom', affix: false, parent:"/investment/custom"}
            },
            {
                path: 'propertyViewings',
                component: () => import('@/pages/investment/propertyViewings/list.vue'),
                name: 'propertyViewings',
                hidden: true,
                meta: { title: '带看管理', activeMenu: '/investment/propertyViewings', affix: false}
            },
            {
                path: 'custom/visit',
                component: () => import('@/pages/investment/propertyViewings/visit/list.vue'),
                name: 'custom/visit',
                hidden: true,
                meta: { title: '到访管理', activeMenu: '/investment/propertyViewings', affix: false, parent:"/investment/custom"}
            },
            {
                path: 'ranking',
                component: () => import('@/pages/investment/ranking/list.vue'),
                name: 'ranking',
                hidden: true,
                meta: { title: '带看/成交排行榜', activeMenu: '/investment/ranking', affix: false}
            },
        ]
    },
    {
        path: "/cockpit",
        component: ecareLay,
        redirect: "/cockpit",
        children: [
            {
                path: "/cockpit",
                meta: {title: "首页", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/cockpit/index.vue"),
            }
        ],
    },
]

const createRouter = () =>
    new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        routes: commonRouters,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;
