import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    saveUcIntendedCustomer(data){
        return request({
            url: getUrl("intendedCustomerFeign/saveUcIntendedCustomer"),
            method: "post",
            data,
        })
    },
    deleteUcIntendedCustomer(data){
        return request({
            url: getUrl("intendedCustomerFeign/deleteUcIntendedCustomer"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerVo(data){
        return request({
            url: getUrl("intendedCustomerFeign/getUcIntendedCustomerVo"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerPage(data){
        return request({
            url: getUrl("intendedCustomerFeign/getUcIntendedCustomerPage"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerConsultPage(data){
        return request({
            url: getUrl("intendedCustomerFeign/getUcIntendedCustomerConsultPage"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerConsultVo(data){
        return request({
            url: getUrl("intendedCustomerFeign/getUcIntendedCustomerConsultVo"),
            method: "post",
            data,
        })
    },
    saveUcIntendedCustomerConsult(data){
        return request({
            url: getUrl("intendedCustomerFeign/saveUcIntendedCustomerConsult"),
            method: "post",
            data,
        })
    },
    deleteUcIntendedCustomerConsult(data){
        return request({
            url: getUrl("intendedCustomerFeign/deleteUcIntendedCustomerConsult"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerFollowupList(data){
        return request({
            url: getUrl("intendedCustomerFeign/getUcIntendedCustomerFollowupList"),
            method: "post",
            data,
        })
    },
    saveIntendedCustomerFollowup(data){
        return request({
            url: getUrl("intendedCustomerFeign/saveIntendedCustomerFollowup"),
            method: "post",
            data,
        })
    },
    deleteIntendedCustomerFollowup(data){
        return request({
            url: getUrl("intendedCustomerFeign/deleteIntendedCustomerFollowup"),
            method: "post",
            data,
        })
    },
}
