<template>
  <div class="tinymce-editor" style="height: calc(100% - 56px);">
    <Editor v-model="content" :init="editorInit" :disabled="disabled" :api-key="apiKey" @onClick="onClick"></Editor>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Editor,
  },
  props: {
    //传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    apiKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      originalForm: {},
      content: this.value,
      //初始化配置
      editorInit:{
        language_url: `/editor/langs/zh_CN.js`,
        language: 'zh_CN',
        height: 500,
        width:900,
        convert_urls: false,
        plugins: ['advlist anchor autolink customAttach autosave code codesample colorpicker  contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'],
        toolbar: ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
        images_upload_handler: function(blobInfo, success, failure) {
          const formData = new FormData()
          formData.append('file', blobInfo.blob())
          formData.append('path', 'news/img')
          uploadPic(formData).then(res=>{
            success(res.data)
          }).catch(() => {
            failure('上传失败，请重新上传')
          })
        },
        file_picker_callback: function(callback, value, meta) {
          //文件分类
          let filetype='.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';
          //后端接收上传文件的地址
          let upurl='/api/oss/uploadNewsFile';
          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', filetype);
          input.click();
          input.onchange = function() {
            let file = this.files[0];
            let xhr, formData;
            xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open('PcdOST', upurl);
            xhr.onload = function() {
              let json;
              if (xhr.status != 200) {
                return;
              }
              json = JSON.parse(xhr.responseText);
              if (!json || typeof json.location != 'string') {
                return;
              }
              callback(json.location);
            };
            formData = new FormData();
            formData.append('file', file, file.name );
            // uploadNewsFile(formData).then(res=>{
            //   callback(res.data, {text: file.name});
            // }).catch(() => {
            //   console.log('上传失败，请重新上传')
            // })
          };
        }
      },
    }
  },
  mounted() {

  },
  methods: {
    //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    //需要什么事件可以自己增加
    onClick(e) {

    },
  },
  watch: {
    value(newValue) {
      this.content = newValue
    },
  },
}
</script>

<style scoped>
.tox-tinymce-aux{z-index:99999 !important;}
.tinymce.ui.FloatPanel{z-Index: 99;}
</style>