import axios from 'axios'
import router from '../router'
import {Message } from 'element-ui'
// import store from '@/store'
import {getToken, removeToken, setToken} from '@/utils/auth'
import store from '@/store'
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: "https://gateway.wlmqgtzs.com", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (getToken()){
        config.headers['UchungToken'] = getToken()
        // let user = store.getters.user;
        // console.log("request==>user",user)
        // if (!user.canDelete){
        //     // 将URL转换为小写，然后检查是否包含关键字"del"或"remove"
        //     const lowercaseUrl = config.url.toLowerCase();
        //     if (lowercaseUrl.includes('del') || lowercaseUrl.includes('remove')) {
        //         // 在这里可以执行你的逻辑，例如取消请求、显示提示、记录日志等
        //         console.log(`拦截请求: ${config.url}`);
        //         // this.$notify.error({
        //         //     title: '错误',
        //         //     message: "没有删除权限"
        //         // });
        //         return;
        //     }
        // }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    console.log(response)
    if(response.headers['UchungToken']!=undefined){
        setToken(response.headers['UchungToken'])
    }
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      if (res.code == 500){
          removeToken();
          router.push(`/login?redirect=`+router.currentRoute.fullPath)
      } else if (res.code == 500001) {
          console.log(res.message)
          return Promise.reject({code: res.code, msg: res.message})
      }else {
          return Promise.reject(res)
      }
    } else {
      return res
    }
  },
  error => {
    console.log(error.response)
    if (error.response.status==401){
        return Promise.reject({code:401,data:null,msg:"暂无权限"})
    }else {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
    }
  }
)

export default service