import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    save(data){
        return request({
            url: getUrl("visit/save"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("visit/del"),
            method: "post",
            data,
        })
    },
    info(data){
        return request({
            url: getUrl("visit/info"),
            method: "post",
            data,
        })
    },
    list(data){
        return request({
            url: getUrl("visit/list"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("visit/page"),
            method: "post",
            data,
        })
    },
    examine(data){
        return request({
            url: getUrl("visit/examine"),
            method: "post",
            data,
        })
    },
}
