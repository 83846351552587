import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    save(data){
        return request({
            url: getUrl("file/save"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("file/del"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("file/page"),
            method: "post",
            data,
        })
    }
}