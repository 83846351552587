import request from "@/utils/request";

function getUrl(url) {
    return "/business/" + url;
}

export default {
    pageJson(data) {
        return request({
            url: getUrl("cockpit/middlePage.json"),
            method: "post",
            data,
        })
    },
    getSharSpaceGoods(data) {
        return request({
            url: getUrl("cockpit/getSharSpaceGoods.json"),
            method: "post",
            data,
        })
    },
    getSpaceProductOrders(data) {
        return request({
            url: getUrl("cockpit/getSpaceProductOrders.json"),
            method: "post",
            data,
        })
    },
    getShareSpaceProductOrder(data) {
        return request({
            url: getUrl("cockpit/getShareSpaceProductOrder.json"),
            method: "post",
            data,
        })
    },
    getIdleRateBuildingRooms(data) {
        return request({
            url: getUrl("cockpit/getIdleRateBuildingRooms.json"),
            method: "post",
            data,
        })
    },
    getOccupancyStatusSpaceProducts(data) {
        return request({
            url: getUrl("cockpit/getOccupancyStatusSpaceProducts.json"),
            method: "post",
            data,
        })
    },
    getCustomerTransactionVolume(data) {
        return request({
            url: getUrl("cockpit/getCustomerTransactionVolume.json"),
            method: "post",
            data,
        })
    },
    getTakeLookTheLeaderboard(data) {
        return request({
            url: getUrl("cockpit/getTakeLookTheLeaderboard.json"),
            method: "post",
            data,
        })
    },
    getTextScroll(data) {
        return request({
            url: getUrl("cockpit/getTextScroll.json"),
            method: "post",
            data,
        })
    }


}
