<template>
  <div>
    <el-input :placeholder="placeholder" v-model="filterText" clearable></el-input>
    <!--    <el-tree-->
    <!--        style="margin-top: 10px"-->
    <!--        :data="list"-->
    <!--        :props="defaultProps"-->
    <!--        node-key="catId"-->
    <!--        ref="menuTree"-->
    <!--        @node-click="nodeclick"-->
    <!--        :filter-node-method="filterNode"-->
    <!--        :highlight-current = "true"-->
    <!--          :default-expand-all="showCheckbox"-->
    <!--        :render-content="renderContent"-->
    <!--    >-->
    <!--    </el-tree>-->
    <el-tree
        style="margin-top: 10px"
        :data="list"
        :props="defaultProps"
        node-key="catId"
        ref="menuTree"
        @node-click="nodeclick"
        :filter-node-method="filterNode"
        :highlight-current="true"
        :default-expand-all="showCheckbox"
    >
         <span slot-scope="{ node, data }">
           <div style="display: flex">
             <div class="label-text" :style="node.childNodes.length > 0?'width: 120px':'width: 102px;'">
                {{node.childNodes.length > 0 ? '' : '● '}}{{ node.label }}
             </div>
             <div v-if="button">
               <el-tag size="mini" @click.stop="butClick(node)">{{ buttonText }}</el-tag>
<!--               <el-button  size="mini" @click.stop="butClick(node)">{{ buttonText }}</el-button>-->
             </div>
           </div>
<!--           <span class="label-text" :title="node.label">-->
<!--             {{node.childNodes.length > 0 ? '' : '●'}}{{ node.label }}-->
<!--           </span>-->
<!--           <span v-if="button">-->
<!--            <el-button type="text" size="mini" @click.stop="butClick(node)">{{ buttonText }}</el-button>-->
<!--           </span>-->
        </span>
    </el-tree>
  </div>

</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: ''
    },
    nodeName: {
      type: String,
      default: 'name'
    },
    list: {
      type: Array,
      default: function () {
        return []
      }
    },
    defaultProps: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: '输入关键字进行过滤',
    },
  },
  data() {
    //这里存放数据
    return {
      filterText: "",
    };
  },
  //计算属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    filterText(val) {
      this.$refs.menuTree.filter(val);
    }
  },
  //方法集合
  methods: {
    renderContent: function (h, {node, data, store}) {
      let addElement = arguments[0];
      if (node.childNodes.length > 0) {
        return addElement("span", [
          addElement("i", {class: "icon"}), // 类名自定义图标
          addElement("span", "    "),
          addElement("span", {style: "font-size: 15px;"}, arguments[1].node.label),
          addElement("el-link", {style: "font-size: 10px;color: #909399;margin-left: 15px"}, '点击查看'),
        ]);
      } else {
        return addElement("span", [
          addElement("span", "●"), //可以自定义图标
          addElement("span", "    "),
          addElement("span", {style: "font-size: 15px;"}, arguments[1].node.label),
        ]);
      }
    },
    //树节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data[this.nodeName].indexOf(value) !== -1;
    },
    nodeclick(data, node, component) {
      console.log("子组件category的节点被点击", data, node, component);
      //向父组件发送事件；
      this.$emit("tree-node-click", data, node, component);
    },
    butClick(node) {
      this.$emit("butClick", node);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
  }, //生命周期 - 销毁完成
  activated() {
  } //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
/deep/ .el-tree-node__content {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

.label-text {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
