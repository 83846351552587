import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    save(data){
        return request({
            url: getUrl("goodsContract/save"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("goodsContract/del"),
            method: "post",
            data,
        })
    },
    info(data){
        return request({
            url: getUrl("goodsContract/info"),
            method: "post",
            data,
        })
    },
    list(data){
        return request({
            url: getUrl("goodsContract/list"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("goodsContract/page"),
            method: "post",
            data,
        })
    },
    generateContract(data){
        return request({
            url: getUrl("goodsContract/generateContract"),
            method: "post",
            data,
        })
    },
    generateTotalOrderContract(data){
        return request({
            url: getUrl("goodsContract/generateTotalOrderContract"),
            method: "post",
            data,
        })
    },
    generateContractName(data){
        return request({
            url: getUrl("goodsContract/generateContractName/"+data),
            method: "get"
        })
    },
    generateNewContract(data){
        return request({
            url: getUrl("goodsContract/generateNewContract"),
            method: "post",
            data,
        })
    }
}
