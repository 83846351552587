import request from "@/utils/request";

function getUrl(url) {
    return "/business/" + url;
}

export default {
    getHomeColorBlock(data) {
        return request({
            url: getUrl("home/getHomeColorBlock"),
            method: "post",
            data,
        })
    },
    getCustomerWeeklyData(data) {
        return request({
            url: getUrl("home/getCustomerWeeklyData"),
            method: "post",
            data,
        })
    },
    getAnnualSalesQuotaSpaceGoods(data) {
        return request({
            url: getUrl("home/getAnnualSalesQuotaSpaceGoods"),
            method: "post",
            data,
        })
    },
    getSharAnnualSalesQuotaSpaceGoods(data) {
        return request({
            url: getUrl("home/getSharAnnualSalesQuotaSpaceGoods"),
            method: "post",
            data,
        })
    },
}
