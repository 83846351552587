import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    save(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/save"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/del"),
            method: "post",
            data,
        })
    },
    info(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/info"),
            method: "post",
            data,
        })
    },
    list(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/list"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/page"),
            method: "post",
            data,
        })
    },
    createOrders(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/createOrders"),
            method: "post",
            data,
        })
    },
    createOrdersItems(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/createOrdersItems"),
            method: "post",
            data,
        })
    },
    createOrdersItemsBatch(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/createOrdersItemsBatch"),
            method: "post",
            data,
        })
    },
    payOrdersItems(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/payOrdersItems"),
            method: "post",
            data,
        })
    },
    refundOrdersItems(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/refundOrdersItems"),
            method: "post",
            data,
        })
    },
    getOrdersItemsStatus(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/getOrdersItemsStatus"),
            method: "post",
            data,
        })
    },
    confirmRefundOrdersItems(data){
        return request({
            url: getUrl("spaceGoodsOrdersItems/confirmRefundOrdersItems"),
            method: "post",
            data,
        })
    },
    countPrice(data){
        return request({
            url: getUrl("spaceGoodsOrders/countPrice"),
            method: "post",
            data,
        })
    }
}
