<template>
  <el-container >
    <el-aside width="170px">
      <sidebar></sidebar>
    </el-aside>
    <el-container>
      <el-header>
        <ecare-header></ecare-header>
      </el-header>
      <el-main>
        <div class="app-container">
          <router-view/>
        </div>
      </el-main>
      <el-footer height="40px">
        <div style="text-align: center;color: #c8c9cc">
          Copyright ©2023 乌鲁木齐经济技术开发区高铁枢纽综合投资（集团）有限公司. 版权所有.
        </div>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import Sidebar from "@/ecareLay/compoments/Sidebar";
import AppMain from "@/ecareLay/compoments/AppMain";
import EcareHeader from "@/ecareLay/compoments/Header";
export default {
  name: "index",
  components: {AppMain, Sidebar,EcareHeader}
}
</script>

<style>

</style>