import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    save(data){
        return request({
            url: getUrl("spaceGoods/save"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("spaceGoods/del"),
            method: "post",
            data,
        })
    },
    info(data){
        return request({
            url: getUrl("spaceGoods/info"),
            method: "post",
            data,
        })
    },
    list(data){
        return request({
            url: getUrl("spaceGoods/list"),
            method: "post",
            data,
        })
    },
    getSpaceGoodsByZsType(data){
        return request({
            url: getUrl("spaceGoods/getSpaceGoodsByZsType"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("spaceGoods/page"),
            method: "post",
            data,
        })
    },
    updPrice(data){
        return request({
            url: getUrl("spaceGoods/updPrice"),
            method: "post",
            data,
        })
    },
    updZsType(data){
        return request({
            url: getUrl("spaceGoods/updZsType"),
            method: "post",
            data,
        })
    },
}
