<template>
  <div style="padding: 20px;box-sizing: border-box">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "AppMain"
}
</script>

<style scoped>

</style>