import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    out(data){
        return request({
            url: getUrl("asset/material/out/out"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("asset/material/out/del"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("asset/material/out/page"),
            method: "post",
            data,
        })
    },
}
