import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    createShareSpaceOrder(data){
        return request({
            url: getUrl("pay/createShareSpaceOrder"),
            method: "post",
            data,
        })
    },
    getShareSpaceCodeUrl(data){
        return request({
            url: getUrl("pay/getShareSpaceCodeUrl"),
            method: "post",
            data,
        })
    },
    getSpaceCodeUrl(data){
        return request({
            url: getUrl("pay/getSpaceCodeUrl"),
            method: "post",
            data,
        })
    },
    getOrderStatus(data){
        return request({
            url: getUrl("pay/getOrderStatus"),
            method: "post",
            data,
        })
    },
    refundOrder(data){
        return request({
            url: getUrl("pay/refundOrder"),
            method: "post",
            data,
        })
    },
    refundSpaceGoodsOrder(data){
        return request({
            url: getUrl("pay/refundSpaceGoodsOrder"),
            method: "post",
            data,
        })
    },
    createSpaceOrder(data){
        return request({
            url: getUrl("pay/createSpaceOrder"),
            method: "post",
            data,
        })
    },
    spaceOrderOfflinePay(data){
        return request({
            url: getUrl("pay/spaceOrderOfflinePay"),
            method: "post",
            data,
        })
    },
}