import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    getUcEnterpriseList(data){
        return request({
            url: getUrl("operate/getUcEnterpriseList"),
            method: "post",
            data,
        })
    },
     addUcEnterprise(data){
            return request({
                url: getUrl("operate/addUcEnterprise"),
                method: "post",
                data,
            })
        },
    saveUcCustom(data){
            return request({
                url: getUrl("operate/saveUcCustom"),
                method: "post",
                data,
            })
        },
}
