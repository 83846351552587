import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    save(data){
        return request({
            url: getUrl("covenantTemplate/save"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("covenantTemplate/del"),
            method: "post",
            data,
        })
    },
    info(data){
        return request({
            url: getUrl("covenantTemplate/info"),
            method: "post",
            data,
        })
    },
    list(data){
        return request({
            url: getUrl("covenantTemplate/list"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("covenantTemplate/page"),
            method: "post",
            data,
        })
    },
}
