import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    getUcSpaceGoodsOrders(data){
        return request({
            url: getUrl("auth/getUcSpaceGoodsOrders"),
            method: "post",
            data,
        })
    },
    getUcSpaceGoodsOrdersItemsPage(data){
        return request({
            url: getUrl("auth/getUcSpaceGoodsOrdersItemsPage"),
            method: "post",
            data,
        })
    },
}