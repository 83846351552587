<template>
  <Card :title="title">
    <template #left>
      <slot name="title-left"></slot>
    </template>
    <template #right>
      <slot name="title-right"></slot>
    </template>
    <el-table :data="data || []" class="table" :header-cell-style="{'text-align':'center'}"
                v-loading="loading" @selection-change="$emit('selection-change', $event)"
              :cell-class-name="tableCellClassName" @row-dblclick="dbclick">
      <el-table-column v-if="selection"
                       type="selection"
                       width="55">
      </el-table-column>
        <el-table-column v-if="hasIndex" width="50">
        <template slot-scope="scope">
          {{hasSum && scope.$index === data.length - 1 ? "" : scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          v-for="(column, index) in columns"
          :key="column.dataIndex"
          :label="column.title"
          :prop="column.dataIndex"
          :current-row-key="rowKey"
          :cell-style="{'text-align':'left'}"
          :width="column.width"
          :type="column.slot=='expand'?'expand':''"
          :show-overflow-tooltip="column.overflow||true"
          :align="column.align||'center'"
          :fixed="column.dataIndex=='action'?'right':false"
      >
        <template slot-scope="scope">
          <div v-if="column.slot">
            <slot :name="column.slot" :scope="{[column.dataIndex]: scope.row[column.dataIndex],index: scope.$index,row: scope.row,}"/>
          </div>
          <span v-else-if="column.isAmount">
            {{
              scope.row[column.dataIndex] !== null &&
              scope.row[column.dataIndex] !== undefined
                  ? (scope.row[column.dataIndex] / 100).toFixed(0)
                  : ""
            }} 元
          </span>
          <span v-else-if="column.customRender">
            {{
              column.customRender(scope.row[column.dataIndex], scope.row, index)
            }}
          </span>
          <span v-else-if="column.imageWidth">
            <el-image style="border-radius: 3px" v-if="scope.row[column.dataIndex]" :style="'width: '+column.imageWidth+'; height: '+column.imageHeight" :src="scope.row[column.dataIndex]"></el-image>
            <div v-else>-</div>
          </span>
          <span v-else-if="column.statusTag">
            <el-tag :type="scope.row[column.dataIndex]?'':'danger'">{{ scope.row[column.dataIndex]?column.statusTag[0]:column.statusTag[1]}}</el-tag>
          </span>
          <span v-else-if="column.options">
            <span v-if="scope.row[column.dataIndex] != null">{{ getOptionsValue(scope.row[column.dataIndex], column.options) }}</span>
            <span v-else>-</span>
          </span>
          <span v-else-if="column.birthDate">
            {{ scope.row[column.dataIndex] | birthDateFormat }}
          </span>
          <span v-else-if="column.date">
            {{ scope.row[column.dataIndex] | dateFormat }}
          </span>
          <span v-else-if="column.payStatus">
            {{ scope.row[column.dataIndex] | orderTradeState }}
          </span>
          <span v-else-if="column.montage">
            <span v-if="column.montage" v-for="(item, index) in column.montage" :key="index">
              <span v-if="scope.row[item]">{{ scope.row[item] }}</span>
              <span v-else>-</span>
            </span>
          </span>
          <span v-else-if="column.filter">
            {{ scope.row[column.dataIndex] + ' ' + applyFilters(scope.row[column.filter], [column.filters]) }}
          </span>
          <span v-else-if="column.picLists">
            <el-image
                v-if="column.picLists && JSON.parse(scope.row[column.dataIndex]).length > 0"
                :style="'width: '+column.picWidth+'; height: '+column.picHeight+';border-radius: 3px;'"
                :src="JSON.parse(scope.row[column.dataIndex])[0]"
                :preview-src-list="JSON.parse(scope.row[column.dataIndex])">
              </el-image>
            <div v-else>暂无图集</div>
          </span>
          <span v-else-if="scope.row[column.dataIndex] && column.text">
            {{ scope.row[column.dataIndex] + ' ' + column.text }}
          </span>
          <span v-else-if="column.statusStyleValues">
            <el-tag v-if="column.statusStyleValues[column.statusStyleValues.findIndex(item => item.value == scope.row[column.dataIndex])].label"
                    :type="column.statusStyleValues[column.statusStyleValues.findIndex(item=>item.value==scope.row[column.dataIndex])].style">
               {{
                    column.statusStyleValues[column.statusStyleValues.findIndex(item => item.value == scope.row[column.dataIndex])].label
                }}
             </el-tag>
            <span v-else>
                {{ '-' }}
              </span>
          </span>
          <span v-else-if="column.isTo">
            <span @click="to(scope.row[column.toIndex], column.url)" style="color: #00a2ff;cursor: pointer">{{ scope.row[column.dataIndex] || '-'}}</span>
          </span>
          <span v-else>
           <div v-if="column.editor">
              <el-input
                  v-if="'tab'+scope.row.index + ',' + scope.column.index === currentCell"
                  @blur="hideInput(scope.row, scope.column)"
                  @keyup.enter.native="$event.target.blur()"
                  size="mini"
                  :ref="'tab'+scope.row.index + ',' + scope.column.index"
                  v-model="scope.row[column.dataIndex]">
              </el-input>
              <span v-else>{{ scope.row[column.dataIndex] || '-'}}</span>
           </div>
           <div v-else>{{ scope.row[column.dataIndex] || '-'}}</div>
          </span>
        </template>
      </el-table-column>
      <template #empty>
        <div class="empty">
          <img src="../assets/img/empty.png" />
          <div>暂无数据</div>
        </div>
      </template>
    </el-table>

    <el-pagination
        v-if="data && data.length !== 0 && usePagination"
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.pageNum"
        :page-sizes="pagination.pageSizeOptions"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
    >
    </el-pagination>
  </Card>
</template>

<script>
import Card from "@/components/Card";
export default {
  name: "BaseTable",
  components: {Card},
  props: {
    title: {
      type: String,
      default:"列表数据"
    },
    usePagination: {
      type: Boolean,
      default: true,
    },
    // 是否mount时获取数据
    immediate: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    getData: {
      type: Function,
    },
    rowKey: {
      default: "id",
    },
    // 列表是否展示序号列
    hasIndex: {
      type: Boolean,
      default: false,
    },
    hasExpand: {
      type: Boolean,
      default: false,
    },
    // 是否有总计
    hasSum: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    requestFun: {
      type: Function,
    },
    searchForm: {
      type: Object,
      default: () => ({}),
    },
    pages: {
      type: Object,
      default: () => ({
        ststus: false
      }),
    },
  },
  data() {
    return {
      currentCell: null,
      originalForm: {},
      pagination: {
        size: "small",
        total: 0,
        pageSize: 10,
        pageNum: 1,
        pageSizeOptions: ["10", "30", "45", "60", "75", "90", "500"],
      },
      total: 0,
      data: [],
      loading: false,
      // 多选选择的行
      selected: [],
      dragSourceIndex: null,
      oldCell: {},
      confirmShown: false,
    };
  },
  mounted() {
    if (this.immediate) {
      this.getTableData();
    }
  },
  methods:{
    to(ucId, url) {
      if (ucId && url)
       window.open(url+ucId, "_blank");
      else
        this.$message('参数为空');
    },
    tableCellClassName({row, column, rowIndex, columnIndex}){
      row.index=rowIndex;
      column.index=columnIndex;
    },
    // 获得当前双击的单元格的横竖index，然后拼接成一个唯一字符串用于判断，并赋给currentCell
    // 拼接后类似这样："1,0","1,1",
    dbclick(row,column) {
      this.oldCell = { ...row };
      this.currentCell = 'tab'+row.index + ',' + column.index;
      // 这里必须要setTimeout，因为在点击的时候，input才刚被v-if显示出来，不然拿不到dom
      setTimeout(() => {
        this.$nextTick(() => {
          // 双击后自动获得焦点
          if (this.$refs['tab'+row.index + ',' + column.index]) {
            this.$refs['tab'+row.index + ',' + column.index].focus();
          }
        });
      }, 500)
    },
    // 当input失去焦点的时候，隐藏input
    hideInput(row, column){
      this.$nextTick(() => {
        console.log("hideInput()被触发，行：", row, "，数据索引：", column);
        this.currentCell = null;
        let dataIndex = column.property
        if (row[dataIndex] === this.oldCell[dataIndex]) {
          row[dataIndex] = this.oldCell[dataIndex]
          this.$message('您未进行编辑');
        } else {
          this.confirmShown = true;
          this.$confirm('是否要对该列进行编辑？').then(_ => {
            this.$emit('hideInput', row, dataIndex, column.label)
            this.confirmShown =false;
          }).catch(_ => {
            row[dataIndex] = this.oldCell[dataIndex]
            this.$message('您已取消编辑操作');
            this.confirmShown =false;
          });
        }
      });
    },
    applyFilters(value, filters) {
      let filteredValue = value;
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        filteredValue = this.$options.filters[filter](filteredValue);
      }
      return filteredValue;
    },
    async defaultGetData(
        pages = {
          pageNum: 1,
          pageSize: 10,
        }
    ) {
      const res = await this.requestFun({
        ...pages,
        ...this.searchForm,
      });
      return {
        data: res.data?res.data.list:res.result.list,
        total: res.data?res.data.total:res.result.total,
      };
    },
    async getTableData(pageNum = this.pagination.pageNum) {
      if (this.pages.status) {
        pageNum = this.pages.pageNum
      }
      this.pageNum = pageNum;
      this.selected = [];
      this.pagination.pageNum = pageNum;
      this.loading = true;
      const getDataFun = this.getData || this.defaultGetData;
      getDataFun({
        pageNum,
        pageSize: this.pagination.pageSize - this.hasSum,
      }).then(({ data, total, callbackFn }) => {
            this.data = data;
            this.total = total - 0;
            this.pagination.total = total;
            callbackFn && callbackFn();
      }).finally(() => {
            this.loading = false;
      });
    },
    // 修改每页数量
    handleSizeChange(val) {
      this.pagination.pageNum = 1;
      this.pagination.pageSize = val;
      this.getTableData();
    },
    // 切换页面
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.getTableData();
    },
    // 当列是选项时，获取对应值
    getOptionsValue(value, options) {
      return options.find((item) => item.value === value).label;
    },
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}
.table {
  margin: 0 20px;
  width: auto;
}
::v-deep thead tr {
  background: #91d4f8;
}
::v-deep .el-table th.el-table__cell {
  color: #444;
  background: #91d4f8;
}
.empty {
  margin: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #666666;
  line-height: 33px;
  img {
    margin-bottom: 20px;
    width: 136px;
    height: 88px;
  }
}
</style>
