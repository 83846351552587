import {Loading} from "element-ui";

const options =  {
    lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
}
let loading = null

export default {
    install(Vue) {
        Vue.prototype.$startLoading = function (text) {
            options.text = text
            loading = Loading.service(options);
        }
        Vue.prototype.$endLoading = function () {
            loading.close()
        }
    },
}
