import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    getBuildingFloorList(data){
        return request({
            url: getUrl("www/getBuildingFloorList"),
            method: "post",
            data,
        })
    },
    getSpaceGoodsList(data){
        return request({
            url: getUrl("www/getSpaceGoodsList"),
            method: "post",
            data,
        })
    },
}