import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    list(data){
        return request({
            url: getUrl("dept/list"),
            method: "post",
            data,
        })
    },
    deptTree(data){
        return request({
            url: getUrl("dept/deptTree"),
            method: "post",
            data,
        })
    },
    getDept(data){
        return request({
            url: getUrl("dept/getInfo/"+data),
            method: "post",
            data,
        })
    },
    delDept(data){
        return request({
            url: getUrl("dept/del/"+data),
            method: "post",
            data,
        })
    },
    listDeptExcludeChild(data){
        return request({
            url: getUrl("dept/list/exclude/"+data),
            method: "post",
            data,
        })
    },
    updateDept(data){
        return request({
            url: getUrl("dept/updateDept"),
            method: "post",
            data,
        })
    },
    addDept(data){
        return request({
            url: getUrl("dept/addDept"),
            method: "post",
            data,
        })
    },
}
