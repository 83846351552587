import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    save(data){
        return request({
            url: getUrl("building/save"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("building/del"),
            method: "post",
            data,
        })
    },
    info(data){
        return request({
            url: getUrl("building/info"),
            method: "post",
            data,
        })
    },
    list(data){
        return request({
            url: getUrl("building/list"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("building/page"),
            method: "post",
            data,
        })
    },
    getBuildingFloorList(data){
        return request({
            url: getUrl("building/getBuildingFloorList"),
            method: "post",
            data,
        })
    },
    children(data){
        return request({
            url: getUrl("building/children"),
            method: "get",
            data,
        })
    },
}
