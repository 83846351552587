import { getToken, setToken, removeToken } from '@/utils/auth'
import {login,getUserInfo,getMenuTree} from  '../../api/auth'

const state = {
  token: getToken(),
  roles: [],
  userInfo:{},
  menus:[
    {
      path: "/",
      redirect: "/dashboard",
      children:[
        {
          name:'首页',
          icon:"el-icon-ed-caidan",
        }
      ]
    }
  ]
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERNAME: (state, userName) => {
    state.userName = userName
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_MENU: (state, menus) => {
    state.menus = menus
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(res=>{
        commit('SET_TOKEN', getToken())
        setToken(res.result.tokenValue)
        resolve()
      }).catch(error=>{
        reject(error)
      })
    })
  },


  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      //获取用户个人信息数据
      getUserInfo().then(res=>{
        commit('SET_USER_INFO', res.result)
        commit('SET_ROLES', res.result.roleIds)
        resolve(res)
      }).catch(error=>{
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      //获取用户个人信息数据
      removeToken()
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_USER_INFO', {})
      commit('SET_MENU', {})
      resolve()
    })
  },

  getMenuTree({ commit }, menu) {
    return new Promise((resolve, reject) => {
      getMenuTree(menu).then(res=>{
        commit('SET_MENU', res.result)
        resolve(res)
      }).catch(error=>{
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
