<template>
  <el-select v-model="copyValue"
             :disabled="disabled"
             filterable
             clearable
             remote
             @clear="selectClear"
             placeholder="请选择"
             @change="updateValue"
             :remote-method="remoteMethod"
             @focus="focusMethod">
    <el-option
        v-for="item in dataList"
        :key="item[valueKey]"
        :label="item[labelKey]"
        :value="item[valueKey]"
    >
    </el-option>
    <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-size="pageInfo.pageSize"
        layout=" prev, pager, next,total"
        :total="pageInfo.total">
    </el-pagination>

  </el-select>
</template>

<script>
export default {
  name: "paginationSelect",
  props: {
    //绑定值
    value: String,
    //下拉列表
    dataList: Array,
    //option的label在列表中对应的key
    labelKey: String,
    //option的value在列表中对应的key
    valueKey: String,
    //分页信息
    pageInfo: Object,
    disabled: false,
    elSelectClass: Boolean,
  },
  watch: {
    value: {
      handler: function (val) {
        this.copyValue = val;
      },
      deep: true
    }
  },
  methods: {
    //更新值
    updateValue(val) {
      this.$emit('selectChange', this.copyValue);
    },
    remoteMethod (query) {
      this.copyValue = query;
      this.$emit('remoteSearch', query);
    },
    focusMethod () {
      if (!this.dataList || (this.dataList && this.dataList.length == 0)) {
        this.filterMethod();
      }
    },
    //清楚输入框内容
    selectClear () {
      this.$emit('selectPageChange', 1, this.pageInfo.pageSize, "clear");
    },
    //翻页
    handleCurrentChange(currenPageNo) {
      this.$emit('selectPageChange', currenPageNo, this.pageInfo.pageSize);
    }
  },
  data() {
    return {
      originalForm: {},
      copyValue: this.value
    };
  }
};
</script>

<style scoped>
.elPaginationSelect {
  width: 100%;
  border: none !important;
}

.el-input input {
  border: none !important;
  width: 100%;
}

</style>