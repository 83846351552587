import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    save(data){
        return request({
            url: getUrl("spaceGoodsOrders/save"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("spaceGoodsOrders/del"),
            method: "post",
            data,
        })
    },
    info(data){
        return request({
            url: getUrl("spaceGoodsOrders/info"),
            method: "post",
            data,
        })
    },
    list(data){
        return request({
            url: getUrl("spaceGoodsOrders/list"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("spaceGoodsOrders/page"),
            method: "post",
            data,
        })
    },
    pageByGoodsId(data){
        return request({
            url: getUrl("spaceGoodsOrders/pageByGoodsId"),
            method: "post",
            data,
        })
    },
    createOrders(data){
        return request({
            url: getUrl("spaceGoodsOrders/createOrders"),
            method: "post",
            data,
        })
    },
    createOrdersNews(data){
        return request({
            url: getUrl("spaceGoodsOrders/createOrdersNews"),
            method: "post",
            data,
        })
    },
    getPageByBroker(data){
        return request({
            url: getUrl("spaceGoodsOrders/getPageByBroker"),
            method: "post",
            data,
        })
    },
    sendOrders(data){
        return request({
            url: getUrl("spaceGoodsOrders/sendOrders"),
            method: "post",
            data,
        })
    },
    sendNotice(data){
        return request({
            url: getUrl("spaceGoodsOrders/sendNotice"),
            method: "post",
            data,
        })
    }
}
