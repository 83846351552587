import request from "@/utils/request";
function getUrl(url) {
    return "/business/" + url;
}

export default {
    feign(data){
        return request({
            url: getUrl("feign/addUcEnterprise"),
            method: "post",
            data,
        })
    },
}