import request from "@/utils/request";
function getUrl(url) {
     return "/business/" + url;
}
export default {
    policy(data){
        return request({
            url: getUrl("oss/policy"),
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data
        })
    },
}
